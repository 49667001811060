(function(root, factory) {
    (typeof exports === 'object') && (module.exports = factory())
    || ((typeof define === 'function' && define.amd) && define(factory))
    || factory()
}(this, function() {
    return App.module('Requests', function(Requests, App, Backbone, Marionette, $, _){
        'use strict';

        Requests._sendBasic = Requests.send = function(options){
            var dfd = $.Deferred();

            var fragment = options.fragment || null;

            $.ajax({
                url: options.requestEndpoint,
                type: options.requestType || 'get',
                dataType: options.requestDataType || 'json',
                contentType: options.contentType,
                data: options.data || ''
            })
                .then(this.handleResponse)
                .then(_.bind(this.handleResponseFragment, this, fragment))
                .done(dfd.resolve)
                .fail(dfd.reject);

            return dfd.promise();
        };

        Requests.handleResponse = function(response){
            var dfd = $.Deferred();
            // response status check and handling
            if (_.isObject(response) && (response.errors && response.errors.length) || (response.output && response.output.errors && response.output.errors.length)) {
                var errorSource = response.errors && response.errors.length ? response.errors : response.output.errors;
                var nonStandardErrors = _.filter(errorSource, function (error) {
                    return !('code' in error) && !('message' in error);
                });
                dfd.reject(errorSource, {server: true, nonstandardErrors: nonStandardErrors});
                return dfd.promise();
            }
            dfd.resolve(response.output);
            return dfd.promise();
        };

        Requests.handleResponseFragment = function(fragment, output){
            var dfd = $.Deferred();
            dfd.resolve(fragment ? output[fragment]: output);
            return dfd;
        };

        Requests.handleResponseStatus = function(response){
            var dfd = $.Deferred();
            dfd.resolve(response.status);
            return dfd;
        };

        Requests.handleResponseWithFragment = function(fragment, output){
            return this.handleResponse(output).then(this.handleResponseFragment.bind(this, fragment));
        };

    });
}));